body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.emojiButton {
  cursor: pointer;
  border-radius: 10px;
  padding: 10px;
  font-size: 20px;
  color: white;
  background: #2c2c2c;
}

.colorTitle {
  font-size: 72px;
  background: -webkit-linear-gradient(#eee, #333);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.colorgrad {
  font-size: 72px;
  background: rgba(0, 0, 0, 0)
    repeating-linear-gradient(
      -45deg,
      rgb(0, 84, 253) 0%,
      rgb(255, 25, 247) 7.14%,
      rgb(255, 46, 93) 14.28%,
      rgb(255, 170, 7) 21.42%,
      rgb(255, 170, 7) 28.56%,
      rgb(255, 46, 93) 35.7%,
      rgb(255, 25, 247) 42.84%,
      rgb(0, 84, 253) 50%
    )
    repeat scroll 0% 0% / 600vw 600vw padding-box text;
  background-color: rgba(0, 0, 0, 0);
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-attachment: scroll;
  background-image: repeating-linear-gradient(
    -45deg,
    rgb(0, 84, 253) 0%,
    rgb(255, 25, 247) 7.14%,
    rgb(255, 46, 93) 14.28%,
    rgb(255, 170, 7) 21.42%,
    rgb(255, 170, 7) 28.56%,
    rgb(255, 46, 93) 35.7%,
    rgb(255, 25, 247) 42.84%,
    rgb(0, 84, 253) 50%
  );
  background-size: 600vw 600vw;
  background-origin: padding-box;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-animation: 10s linear 0s infinite normal forwards running
    followThrough;
  -moz-animation: 10s linear 0s infinite normal forwards running followThrough;
  animation: 10s linear 0s infinite normal forwards running followThrough;

  -webkit-text-fill-color: transparent;
}

@keyframes followThrough {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 50% 50%;
  }
  100% {
    background-position: 0% 0%;
  }
}

@-webkit-keyframes followThrough {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 50% 50%;
  }
  100% {
    background-position: 0% 0%;
  }
}

@-moz-keyframes followThrough {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 50% 50%;
  }
  100% {
    background-position: 0% 0%;
  }
}

.emojiButton:hover {
  background: black;
}

.emojiSpot {
  background-color: #2c2c2c;
}

.emojiSpot:hover {
  background-color: black;
}
